@import "styles/variables.module";

.announcement {
  color: $white;
  user-select: none;
  cursor: default;

  position: relative;

  background: $gradient-blue;

  &-grid {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 48px;

    h1{
      width: 90%;
    }
  }

  svg {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 1365px) {
  .announcement svg{
    width: 200px;
    height: 200px;
  }
}


@media screen and (max-width: 1023px) {
  .announcement {
    svg {
      display: none;
    }

    button {
      max-width: unset;
      width: 100%;
    }
  }
}