@import 'styles/variables.module.scss';

.input {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 6px;

  .label {
    color: $black;
  }

  .input-border {
    border: 1px solid $grey;
    border-radius: $radius-3;
    padding: 16px;
    height: 50px;
    box-sizing: border-box;
    grid-column-gap: 16px;

    input {
      font-size: inherit;
      border: none;
      font-weight: 400;
      width: 100%;

      margin: 0;

      background: transparent;

      color: $grey-darken-1;

      &:focus {
        outline: none;
      }

      &:disabled {
        color: inherit;
      }
    }
  }

  .error{
    border-color: #EA3C27 !important;
  }

}