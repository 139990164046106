.admin-election-results-page {
  .winner {
    width: 100%;
    margin-bottom: 40px;
  }

  .candidates-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    grid-gap: 40px;

    .candidate-result-card {
      max-width: 400px;
      .headers {
        h4 {
          font-weight: 700;
        }
      }
    }
  }
}