@import "styles/variables.module.scss";

.candidate {
  padding: 460px 12px 24px 12px;
  height: 554px;
  max-width: 384px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  img {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: 100%;
    max-height: 460px;
  }

  button {
    position: absolute;
    top: 404px;
    background-color: rgba(0,0,0,.7);
    border-radius: $radius-1;
    color: $green;
    font-weight: 500;
    max-height: 44px;
    font-size: 16px;
    padding: 14px 12px;

    svg {
      margin-left: 127px;
    }

    &:hover {
      background-color: rgba(0,0,0,.8);
    }

    &:active {
      top: 406px;
    }
  }

  .description {
    height: 100%;
    row-gap: 10px;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1023px) {
  .candidate {
    min-width: 320px;
    height: 477px;

    img {
      max-height: 383px;
      width: 100%;
      object-fit: cover;
    }

    button {
      width: 320px;
      top: 327px;
      &:active {
        top: 329px;
      }

      svg {
        margin-left: 60px;
      }
    }
  }
}