@import "styles/variables.module.scss";

.candidate-vote-card {
  position: relative;
  padding: 24px 0;

  user-select: none;

  width: 200px;
  border-radius: 16px;
  background-color: $grey-lighten-1;
  cursor: pointer;

  img {
    margin: 0 40px 0 40px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 12px;

    &.greyed {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  .checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  h5 {
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    margin-top: 16px;
  }
}