.candidate-result-card {
  display: grid;
  grid-template-areas: "a b"
                       "a c";

  grid-template-columns: max-content auto;

  grid-column-gap: 32px;

  img {
    grid-area: a;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center;
    filter: drop-shadow(0px -1px 20px rgba(0, 0, 0, 0.03)) drop-shadow(0px 16px 28px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.02)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
    border-radius: 32px;
  }

  .headers {
    grid-area: b;
    h2 {
      margin-bottom: 12px;
    }
    h4 {

    }
  }

  .chips-list {
    align-self: flex-end;
    grid-area: c;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    .chip {
      box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.03), 0px 16px 28px rgba(0, 0, 0, 0.04), 0px 2px 10px rgba(0, 0, 0, 0.02), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }
  }

  &.is_winner {
    img {
      width: 284px;
      height: 284px;
    }
  }
}