header {
  padding: 8.5px 0;
  margin-top: 32px;
  display: flex;
  align-items: center;
  column-gap: 60px;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
  }

  nav {
    display: flex;
    grid-column-gap: 24px;
    white-space: nowrap;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .form-button {
    justify-self: center;
  }

  .profile {
    justify-self: flex-end;
    grid-column-gap: 8px;
  }
}

@media screen and (max-width: 1023px) {
  header{
    column-gap: 20px;

    p {
      display: none;
    }
  }
}