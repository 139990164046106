.login-modal {
  display: grid;
  grid-template-areas: "a d"
                       "c d";
  grid-template-rows: 1fr auto;
  grid-row-gap: 55px;
  grid-column-gap: 43px;

  .headers {
    grid-area: a;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  .chips {
    grid-area: c;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    row-gap: 12px;
  }
  .login-form {
    grid-area: d;
  }
}

@media screen and (max-width: 1365px) {
  .login-modal{
    grid-template-rows: 1fr min-content;
  }
}

@media screen and (max-width: 1023px) {
  .login-modal {
    grid-template-areas: "a"
                         "c"
                         "d";
    grid-template-rows: max-content max-content max-content;
    grid-row-gap: 24px;
  }
}

