main {
  margin-top: 40px;
  display: grid;
  grid-row-gap: 80px;

  .elections-wrapper {
    display: grid;
    grid-template-areas: "a b"
                         "c c";
    grid-row-gap: 40px;

    h3 {
      grid-area: a;
    }

    .elections-grid {
      grid-area: c;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }
  }
}

@media screen and (max-width: 1023px){
  main {
    .elections-wrapper {
      grid-template-areas: "a"
                         "b"
                         "c";
    }
  }
}