@import "styles/variables.module.scss";

label {
  cursor: pointer;

  input {
    display: none;
  }
  .avatar-input {
    height: 220px;
    border: 1px solid $grey;
    border-radius: $radius-3;
    row-gap: 16px;
    flex-direction: column;

    .avatar-slot {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;

      position: relative;

      .camera-icon {
        position: absolute;
        background-color: $grey-darken-2;
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }

      svg {
        width: 42px;
        height: 42px;
      }

      img {
        position: absolute;
        height: 100px;
        width: 100px;
        object-fit: cover;
      }
    }

    p {
      text-align: center;
      color: $grey-darken-1;
    }

    &:hover {
      .camera-icon {
        z-index: 9999;
        background-color: rgba(0,0,0,.5);
      }
    }
  }
}