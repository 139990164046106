@import 'styles/variables.module.scss';

.textarea-wrapper {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 6px;

  .label {
    font-size: 12px;
    font-weight: 400;
    color: $black;
  }

  textarea {
    border: 1px solid $grey;
    border-radius: $radius-3;
    padding: 16px;
    font-size: 18px;
    font-weight: 400;

    white-space: pre-line;

    margin: 0;
    resize: none;

    background: transparent;

    color: $grey-darken-1;
  }
}