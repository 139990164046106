@import "variables.module";
@import "colors";
@import "UIkit";
@import "functions";


input, button, body {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.App {
  margin: 0 size_desktop(400);
}

a {
  text-decoration: none;
  color: inherit;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1920px) {
  .App {
    margin: 0 size_desktop(156);
  }
}

@media screen and (max-width: 1280px) {
  .App {
    margin: 0 size_desktop(20);
  }
}

@media screen and (max-width: 1023px) {
  .App {
    margin: 0 size_mobile(16);
  }
}

::-webkit-scrollbar {
  display: none;
}