@import "styles/variables.module.scss";

.login-form {
  color: $black;
  background-color: $white;
  width: 384px;
  height: max-content;

  display: flex;
  flex-direction: column;

  .switch {
    margin-bottom: 25px;
  }

  .checkbox {
    margin: 13px 0 20px 0;
  }

  .input {
    margin-bottom: 12px;
  }

}

@media screen and (max-width: 1023px) {
  .login-form {
    width: auto;
  }
}