@import "styles/variables.module.scss";

.question {
  border-radius: $radius-2;
  background-color: $grey-lighten-2;
  padding: 28px 24px 24px 24px;

  user-select: none;
  overflow: hidden;

  position: relative;

  height: max-content;

  transition: all 0.2s;

  button {
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    max-width: unset;
    min-width: unset;
    transform: rotate(0deg);
    background-color: $grey;
  }

  &.hidden {

    button {
      transform: rotate(45deg);
      background-color: $green;
    }

  }

  p {
    margin-top: 16px;
    white-space: pre-line;
    a {
      text-decoration: underline;
      color: $green;
    }
  }

  &.hidden {

    p {
      display: none;
    }
  }
}


@media screen and (max-width: 1023px) {
  .question {
    button {
      right: 16px;
      min-width: 35px;
      min-height: 35px;
    }
  }

  h4 {
    max-width: 25ch;
  }
}