@import "styles/variables.module.scss";

.completed-vote-modal {

  .ant-modal-content {
    max-width: 900px;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
    background-color: $green !important;

    .ss-icon {
      display: none;
    }

    h2 {
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .completed-vote-modal {
    .ant-modal-content {
      .ss-icon {
        bottom: 70px;
        right: 20px;
        position: relative;
        display: block;
      }
    }
  }
}