@import "styles/variables.module";

button {
  max-width: max-content;
  height: max-content;
  min-width: max-content;
  border: unset;
  transition: all 0.25s;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover{
    cursor: pointer;
  }

  &:active {
    position: relative;
    top: 2px;
  }
}

// Sizes

.button--l {
  padding: 22px 40px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 20px;
}

.button--m {
  padding: 16px 36px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 16px;
}

.button--s {
  padding: 11px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
}

.button--xs {
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
}

// Colors

.button--black {
  background-color: $black-lighten-1;
  color: $white;

  &:hover {
    background-color: $black-lighten-2;
  }
}

.button--white {
  background-color: $grey-lighten-1;
  color: $black;

  &:hover {
    background-color: $grey;
  }
}

.button--green {
  background-color: $green;
  color: $white;

  &:hover {
    background-color: $green-lighten-1;
  }
}

.button--transparent {
  background-color: transparent;
  color: $black;

  &:hover {
    background-color: $grey-lighten-1;
  }
}

.disabled {
  background-color: $grey-darken-2;

  &:hover {
    cursor: default;
    background-color: $grey-darken-2;
  }
}

.block {
  max-width: unset;
  width: 100%;
}