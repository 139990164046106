// Headers

h1, h2, h3, h4, h5 {
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 96px;
  line-height: 96px;
}

h2 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
}

h3 {
  font-size: 44px;
  font-weight: 700;
  line-height: 44px;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;

  &.bold {
    font-weight: 700;
  }
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

// Paragraphs

p {
  font-weight: 400;
  margin: 0;
}

.p0 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.p1 {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.p2 {
  font-size: 16px;
  line-height: 16px;
}

.p3 {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.p4 {
  font-size: 12px;
  letter-spacing: -0.2px;
  line-height: 12px;
}

// Cards

.card-1 {
  box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.03),
              0px 16px 28px rgba(0, 0, 0, 0.04),
              0px 2px 10px rgba(0, 0, 0, 0.02),
              0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  padding: 40px 20px;
}

.card-2 {
  box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.03),
              0px 16px 28px rgba(0, 0, 0, 0.04),
              0px 2px 10px rgba(0, 0, 0, 0.02),
              0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 40px;
}

.card-3 {
  border-radius: 20px;
  padding: 20px;
}

@media screen and (max-width: 1023px){
  h1{
    font-size: 35px;
    line-height: 40px;
  }
  h2{
    font-size: 40px;
    line-height: 40px;
  }
  h3{
    font-size: 32px;
    line-height: 32px;
  }
  h4 {
    font-size: 16px;
  }

  .p1 {
    font-size: 14px;
    line-height: 18px;
  }

  .card-2{
    padding: 20px;
  }

  .p2 {
    font-size: 12px;
    line-height: 12px;
  }
}