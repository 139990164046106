@import "styles/variables.module.scss";

.checkbox {
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  cursor: pointer;

  &-input {
    width: 20px;
    height: 20px;
    position: relative;
    border: 1px solid $grey-darken-2;
    border-radius: $radius-5;
    transition: all .2s;
    box-sizing: border-box;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: .9;
    }

    &.checked {
      background-color: $green;
      border-color: $green;

      svg {
        display: block;
      }
    }
  }
  .label {
    font-size: 12px;
    font-weight: 400;
    color: $black;
    user-select: none;
  }
}