.candidates-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  row-gap: 40px;
}

@media screen and (max-width: 1023px) {
  .candidates-list{
    width: 95vw;
    overflow-x: scroll;
    justify-content: normal;
    flex-wrap: nowrap;
    gap: 16px;
    padding-bottom: 40px;
    scroll-snap-type: x;
  }
}