@import "styles/variables.module.scss";

.switch {
  display: grid;
  background-color: $grey-lighten-1;
  max-width: max-content;
  border-radius: $radius-4;
  padding: 4px;

  .selector {
    text-align: center;
    cursor: pointer;
    background-color: unset;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 13px;
    border-radius: 8px;

    &:active {
      position: relative;
      top: 2px;
    }

    &.selected {
      background-color: $white;
    }
  }
}