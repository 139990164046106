$black-lighten-2: #282828;
$black-lighten-1: #181818;
$black: #000000;

$green-lighten-1: #23C182;
$green: #0CB571;

$grey-lighten-2: #FBFBFB;
$grey-lighten-1: #F2F2F2;
$grey: #E1E1E5;
$grey-darken-1: #A6A6A6;
$grey-darken-2: #A0A1A5;

$white: #FFFFFF;

$gradient-green: linear-gradient(98.11deg, #0CB571 0%, #23C182 100%);
$gradient-blue: linear-gradient(104.76deg, #005BEA 0%, #00C6FB 100%);

$radius-5: 6px;
$radius-4: 10px;
$radius-3: 12px;
$radius-2: 16px;
$radius-1: 24px;
$radius-0: 32px;