.candidate-form {
  display: grid;
  grid-row-gap: 28px;
  padding: 40px;

  .avatar-input {
    margin-bottom: 18px;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 18px;
    grid-column-gap: 24px;
  }

  .checkbox {
    margin: 34px 0 30px 0;
    display: flex;
    justify-content: center;
  }
}


@media screen and (max-width: 1023px) {
  .candidate-form {
    padding: 32px 20px;
    form {
      grid-template-columns: 1fr;
    }
  }

}