@import "styles/variables.module";

.election-card {
  color: $white;
  position: relative;
  background: $gradient-green;
  overflow: hidden;
  user-select: none;
  cursor: default;
  white-space: pre-line;

  &-grid {
    display: grid;
    grid-template-areas: "a a"
                         "b c";
    grid-row-gap: 48px;

    h2 {
      white-space: pre-line;
    }

    button {
      justify-self: flex-end;
    }
  }

  button {
    grid-area: c;
    align-self: flex-end;
  }

  &_chips {
    grid-area: b;
  }

  .headers {
    display: grid;
    grid-row-gap: 12px;
  }

  .bg-icon > svg {
    position: absolute;
    top: -30%;
    left: 55%;
    height: 270%;
  }

  .bg-icon > img {
    position: absolute;
    top: 50%;
    transform: translate(50%, -50%);
    right: 20%;
    height: 350px;
  }

  &_chips {
    display: flex;
    grid-gap: 12px;
    z-index: 1;

    &.chips-grid {
      display: grid;
      grid-template-areas: "a a"
                           "b c";
      grid-template-columns: max-content max-content;

      .chip:nth-child(1) {
        grid-area: a;
      }

      .chip:nth-child(2) {
        grid-area: b;
      }

      .chip:nth-child(3) {
        grid-area: c;
      }
    }
  }

  button {
    z-index: 2;
  }
}

@media screen and (max-width: 1023px) {
  .election-card{
    .bg-icon>img, .bg-icon>svg {
      display: none;
    }

    button {
      margin-top: 22px;
      max-width: unset;
      width: 100%;
    }

    &-grid {
      grid-template-areas: "b"
                           "a"
                            "c";
      row-gap: 18px;
    }

    &_chips {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 4px;

      &.chips-grid {
        .chip:nth-child(1) {
          display: none;
        }
        .chip:nth-child(2) {
          grid-area: b;
        }
        .chip:nth-child(3) {
          grid-area: c;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .election-card_chips.chips-grid {
    .chip:nth-child(2) {
      grid-area: a;
    }
    .chip:nth-child(3) {
      grid-area: b;
    }
  }

}