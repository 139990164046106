@import "styles/variables.module.scss";

.admin-election-page {
  h3 {
    margin-bottom: 20px;
  }

  .statistics-grid {
    display: grid;
    grid-template-areas: "a a a a a a b b b b b b"
                       "c c c c d d d d e e e e"
                       "f f f f f f g g g h h h";

    grid-template-columns: repeat(12, 1fr);
    grid-gap: 12px;
    grid-template-rows: auto auto auto;

    &_card:not(.statistics-grid_card.top-3-block){
      max-height: 200px;
    }

    &_card {
      text-align: center;
      padding: 56px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      &.top-3-block {
        .top-3-block_headers {
          margin-bottom: 24px;
          h4 {
            margin-bottom: 12px;
          }
          h4:nth-child(1) {
            font-weight: 700;
          }
          h4:nth-child(2) {
            color: $grey-darken-2;
          }
          h4:nth-child(3) {
            color: $grey-darken-2;
          }
        }
      }

      h3 {
        margin-bottom: 8px;
      }
    }
  }
}