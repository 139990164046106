@import "styles/variables.module.scss";

footer {
  position: relative;
  overflow: hidden;
  background: $black;
  display: grid;
  grid-template-areas: "a b"
                       "c b";
  grid-template-columns: max-content auto;
  grid-column-gap: 110px;
  padding: 40px;
  margin-top: 72px;
  margin-bottom: 32px;
  color: $grey-darken-1;
  height: 144px;

  svg {
    grid-area: a;
  }
  p {
    grid-area: c;
    font-size: 12px;
    align-self: flex-end;
    a {
      color: $white;
    }
  }

  .menus {
    grid-area: b;
    display: flex;
    justify-content: space-between;
  }

  .sut-logo{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .background-logo{
    position: absolute;
    top: -80%;
    right: -20%;
  }
}

.footer-menu {
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;


  &_header {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  a {
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (max-width: 2450px) {
  footer>.background-logo {
    display: none;
  }
}


@media screen and (max-width: 1920px) {
  footer>.background-logo{
    display: block;
    top: -80%;
    right: -20%;
  }
}

@media screen and (max-width: 1500px) {
  footer>.background-logo{
    right: -35%;
  }
}


@media screen and (max-width: 1087px) {
  footer>.background-logo{
    display: none;
  }
}

@media screen and (max-width: 767px) {
  footer {
    grid-template-areas: "a""b""c";
    height: auto;

    .sut-logo{
      display: none;
    }

    .menus {
      flex-direction: column;
      gap: 25px;
      margin: 28px 0;
    }
  }
}