@import "styles/variables.module.scss";

.candidate-page {
  .grid {
    display: grid;
    grid-template-areas: "e e e"
                         "a b b"
                         "a c d";

    grid-gap: 24px;
    grid-template-columns: max-content min-content auto;
  }

  .programs {
    margin-top: 40px;
    grid-gap: 24px;
    display: grid;
    grid-template-columns: 1fr;

    .card-2 {
      height: max-content;
    }

    p {
      white-space: pre-wrap;
    }

    .hidden {
      p {
        max-height: 14ch;
        overflow: hidden;
      }
      button {
        display: block;
      }
    }

    button {
      display: none;
      margin: 24px 0 4px;
      background: unset;
      color: $green;
      font-size: 16px;
      font-weight: 500;

      svg {
        margin-left: 4px;
        width: 18px;
        height: 18px;
        transform: rotateZ(90deg);
      }
    }
  }

  img {
    grid-area: a;
    width: 340px;
    height: 408px;
    object-fit: cover;
    border-radius: 21.287px;
  }

  h3 {
    grid-area: e;
    margin-bottom: 16px;
    font-weight: 700;
  }

  .card-2 {
    padding: 20px;
  }

  &>* {
    height: min-content;
  }

  h4 {
    margin-bottom: 20px;
  }

  .contacts {
    grid-area: c;
    align-self: flex-end;

    .d-flex {
      column-gap: 24px;
    }
  }

  .actions {
    grid-area: d;
    align-self: flex-end;

    &_buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 12px;
    }
  }

  .personal-info {
    grid-area: b;

    .info-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 12px;
      grid-column-gap: 24px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .candidate-page {
    .grid {
      grid-template-areas: "e"
                           "a"
                           "b"
                           "c"
                           "d";
      grid-template-columns: 100%;
    }

    .personal-info {
      .info-grid {
        grid-template-columns: 1fr 1fr;
        .input {
          width: 100%;
        }
      }

    }

    img {
      aspect-ratio: 5/6;
      width: 100%;
      height: 100%;
    }

    .programs {
      grid-template-columns: 1fr;
    }

    .actions {
      &_buttons {
        display: grid;
        grid-template-columns: 1fr;
      }
    }

    .contacts {
      .d-flex {
        margin: 0 60px;
        justify-content: space-around;
        gap: unset;
      }
    }
  }
}