.vote-modal {

  max-width: 900px;

  .chip {
    margin-top: 24px;
  }

  .candidates-votes-list {
    margin: 48px 0;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    justify-content: space-around;
  }
}