@import "styles/variables.module.scss";

.ant-modal {
  display: flex;
  justify-content: center !important;

  &-content {
    background-color: $black-lighten-1 !important;
    color: $white !important;
    border-radius: $radius-0 !important;
    padding: 40px !important;

    .close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      min-width: unset;
      background-color: $grey-darken-1;
      z-index: 9999;
    }
  }
}

@media screen and (max-width: 1365px) {
  .ant-modal{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    top: 0;
  }
  .ant-modal:not(.completed-vote-modal) {
      min-width: 100vw !important;
      height: 100vh !important;
      margin: 0 !important;
      border-radius: unset !important;

      .ant-modal-content{
        width: 100vw;
        height: 100vh !important;
        border-radius: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  }
}


@media screen and (max-width: 1023px) {
  .ant-modal {
    top: 0 !important;
    margin: 0 !important;
    border-radius: unset !important;
    max-width: 100vw !important;
    width: 100%;
    padding-bottom: 0 !important;
    overflow: hidden;

    &-content {
      padding: 32px 20px !important;
      width: 100% !important;
      height: 100% !important;
      min-height: 100vh !important;
      border-radius: 0 !important;
      .close-button, .ant-modal-close {
        top: 32px;
        right: 20px;
      }
    }
  }

  .ant-modal:not(.completed-vote-modal) {
    height: unset !important;
    .ant-modal-content{
      height: unset !important;
    }
  }
}