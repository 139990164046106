@import "variables.module";

.bg-grey-lighten-2 {
  background-color: $grey-lighten-2;
}

.bg-grey-lighten-1 {
  background-color: $grey-lighten-1;
}

.bg-black-lighten {
  background-color: $black-lighten-1;
}

.bg-black {
  background-color: $black;
}

.bg-green {
  background-color: $green;
}

.bg-white {
  background-color: $white;
}

.white {
  color: $white;
}

.black {
  color: $black-lighten-1;
}