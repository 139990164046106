@import "styles/functions.scss";

.width-wrapper {
  max-width: inherit;
  width: inherit;
}

@media screen and (max-width: 1023px) {
  .width-wrapper  {
    max-width: calc(100vw - size_mobile(16 * 2));
  }
}