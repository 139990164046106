@import 'styles/variables.module.scss';


.chip {
  align-content: center;
  background-color: $white;
  max-width: max-content;
  font-weight: 500;
  font-size: 20px;
  padding: 8px 16px 8px 8px;
  grid-column-gap: 8px;
  border-radius: $radius-3;
  height: min-content;

  &-icon {
    width: 28px;
    height: 28px;
    background-color: $green;
    border-radius: 8px;
    padding: 4px;
    box-sizing: border-box;
  }
}

.dark .chip .chip-icon {
  background-color: $black;
}

@media screen and (max-width: 1023px) {
  .chip {
    font-size: 16px;
    padding: 8px 16px 8px 8px;
    grid-column-gap: 8px;
    border-radius: $radius-3;
  }
}
