@import "styles/variables.module.scss";


.admin-election-card {
  color: $white;
  background-color: $green;
  display: flex;
  flex-direction: column;
  row-gap: 18px;

  h2 {
    max-width: 60%;
  }

  .chips-list {
    display: flex;
    column-gap: 12px;
  }

  .actions {
    display: flex;
    column-gap: 12px;
  }
}